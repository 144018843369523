<template>
  <div>
    <TagesgeldIndex />
  </div>
</template>

<script>
import TagesgeldIndex from '../../../wettbewerbsvergleich/src/components/Wettbewerb/Tagesgeld/TagesgeldIndex';

export default {
  name: 'WettbewerbTagesgeldIndex',
  components: { TagesgeldIndex },
};
</script>

<style lang="css" scoped></style>
